import React from "react"
import Navigation from "../components/navigation"
import Section from "../components/section"
import Article from "../components/article"
import Footer from "../components/footer"

const PowerCoaching = () => {
  return (
    <>
      <Navigation />
      <Section color="#fff">
        <Article layout="narrow">Todo: add content</Article>
      </Section>
      <Footer />
    </>
  )
}

export default PowerCoaching
